import {
  mdiCalendarOutline, mdiChatQuestionOutline,
  mdiCreditCardOutline,
  mdiDatabasePlus,
  mdiFileAccount,
  mdiFileAccountOutline,
  mdiFilePdfBoxOutline,
  mdiLan
} from '@mdi/js'

export default [
  {
    title: 'Bewerber',
    icon: mdiFileAccountOutline,

    children: [
      {
        title: 'Bewerber',
        icon: mdiFileAccountOutline,
        to: 'bewerber',
      },
      {
        title: 'Lebenslauf importieren',
        icon: mdiFilePdfBoxOutline,
        to: 'lebenslauf_hochladen_joinvision',
      }
    ]
  },

  {
    title: 'Job',
    icon: mdiLan,
    children: [
      {
        title: 'Definitionen',
        icon: mdiLan,
        to: 'jobDefinition',
      },
      {
        icon: mdiChatQuestionOutline,
        title: 'Interview-Fragen',
        to: 'interviewFrage',
      },
    ],
  },

]
