import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import talenthunter from './talenthunter'
import axios from "@axios";
import VueToastr from 'vue-toastr';
import { initialAbility } from '@/plugins/acl/config.js'

Vue.use(VueRouter)
Vue.use(VueToastr)
const tmpVue = new Vue()

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if(userData !== null && Object.keys(userData).length > 0) return { name: 'bewerber' }
      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...talenthunter,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

const checkLogin = async(to,next) => {
  if(to.meta.resource != "Public"){
    await axios.post('/api/login/', {aktion:'checkUserLogin'})
      .then((res) => {
        const {user,loginState} = res.data
        const {ability: userAbility} = user
        localStorage.setItem('userAbility', JSON.stringify(userAbility))
        delete user.ability
        localStorage.setItem('userData', JSON.stringify(user))
        if(!loginState){
          router.push('/login',()=>{
            tmpVue.$toastr.e('Ihre Loginsitzung ist abgelaufen', 'Sitzung abgelaufen');
          })
        }
      })
      .catch((error) => {
        localStorage.setItem('userData', JSON.stringify({}))
        localStorage.setItem('userAbility', JSON.stringify(initialAbility))
        router.push('/login',()=>{
          tmpVue.$toastr.e('Ihre Loginsitzung ist abgelaufen', 'Sitzung abgelaufen');
        })
      })
  }
}

router.beforeEach((to, _, next) => {
  checkLogin(to,next);
  const userData = JSON.parse(localStorage.getItem('userData'))
  const userAbility = JSON.parse(localStorage.getItem('userAbility'))
  const isLoggedIn = (userData !== null && Object.keys(userData).length > 0 && userAbility !== null && Object.keys(userAbility).length > 0)

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
    return next({ name: 'misc-not-authorized' })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }
  return next()
})

export default router
