
const talenthunter = [
  {
    path: '/login',
    name: 'auth-login',
    props:true,
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/bewerber',
    name: 'bewerber',
    component: () => import('@/views/candidate/CandidateListView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/bewerber/editieren/joinvision/:idThBewerber(.*?)',
    name: 'bewerber_editieren_joinvision',
    props: true,
    component: () => import('@/views/candidate/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/bewerber/erstellen',
    name: 'bewerber_erstellen',
    props: true,
    component: () => import('@/views/candidate/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/jobDefinition',
    name: 'jobDefinition',
    component: () => import('@/views/jobDefinition/JobDefinitionListView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/jobDefinition/interviewFragen/:idThJobDefinition(.*?)',
    name: 'jobDefinition_interviewFragen',
    component: () => import('@/views/jobDefinition/JobDefinitionInterviewQuestions.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/interviewFrage',
    name: 'interviewFrage',
    component: () => import('@/views/jobDefinition/InterviewQuestionListView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/kanal',
    name: 'kanal',
    component: () => import('@/views/channel/ChannelListView.vue'),
      meta: {
      layout: 'content',
    },
  },
  {
    path: '/kanal/erstellen',
    name: 'kanal_erstellen',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/kampagne',
    name: 'kampagne',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/kampagne/erstellen',
    name: 'kampagne_erstellen',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/passwort_vergessen',
    name: 'passwort_vergessen',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/passwort_zuruecksetzen/:hashKey(.*?)',
    name: 'passwort_zuruecksetzen',
    props: true,
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/passwort_neu/:hashKey(.*?)',
    name: 'passwort_neu',
    props: true,
    component: () => import('@/views/NewPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/entsperrung/:hashKey(.*?)',
    name: 'entsperrung',
    props: true,
    component: () => import('@/views/UnlockLogin.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/aktivierung/:hashKey(.*?)',
    name: 'aktivierung',
    props: true,
    component: () => import('@/views/Activation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/konto',
    name: 'konto',
    component: () => import('@/views/Account.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/mitarbeiter',
    name: 'mitarbeiter',
    component: () => import('@/views/EmployeeListView.vue'),
    meta: {
      layout: 'content',
    },
  },


  {
    path: '/mitarbeiter/editieren/:idThHeadhunterMitarbeiter(.*?)',
    name: 'mitarbeiter_editieren',
    props: true,
    component: () => import('@/views/EmployeeView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/mitarbeiter/erstellen',
    name: 'mitarbeiter_erstellen',
    props: true,
    component: () => import('@/views/EmployeeView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/lebenslauf/hochladen/joinvision',
    name: 'lebenslauf_hochladen_joinvision',
    component: () => import('@/views/UploadFormJoinvision.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default talenthunter;
