import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta';
import VueToastr from 'vue-toastr';


const unOrderedDomainCodes = require("./assets/c_th_joinvision_wert.json")
const domainCodes = {};
unOrderedDomainCodes.forEach(item=>{
  if(domainCodes[item.domain] === undefined) domainCodes[item.domain] = [];
  domainCodes[item.domain].push(item);
})
Vue.prototype.$domainCodes = domainCodes

Vue.config.productionTip = false
Vue.use(VueMeta);
Vue.use(VueToastr,{
  position:'toast-top-center',
  timeout:5,
  classNames:['toastrGreen']
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
